// WebSocketManager.js
import { Client } from '@stomp/stompjs';
import SockJS from 'sockjs-client';

class WebSocketManager {
  constructor() {
    this.client = null;
    this.listeners = new Map();
  }

  connect(accountId) {
    this.client = new Client({
      webSocketFactory: () => new SockJS(`${process.env.REACT_APP_API_BASE_URL}ws`, null, {
        transports: ['websocket']
      }),
      onConnect: () => {
        console.log('Connected to WebSocket');
        this.subscribe(accountId);
      },
      onStompError: (frame) => {
        console.error('STOMP error:', frame);
      }
    });

    this.client.activate();
  }

  disconnect() {
    if (this.client) {
      this.client.deactivate();
    }
  }

  subscribe(accountId) {
    if (this.client && this.client.connected) {
      this.client.subscribe(`/topic/account/${accountId}`, (message) => {
        this.handleMessage(message);
      });
    }
  }

  handleMessage(rawMessage) {
    const message = JSON.parse(rawMessage.body);
    console.log('Received WebSocket message:', message);

    // 通知所有监听器
    this.listeners.forEach((callback) => {
      callback(message);
    });
  }

  addMessageListener(key, callback) {
    this.listeners.set(key, callback);
  }

  removeMessageListener(key) {
    this.listeners.delete(key);
  }
}

export default new WebSocketManager();