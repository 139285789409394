import React, { useState, useEffect, useCallback  } from 'react';
import OneMinsDesign from './design/OneMinsDesign';
import Library from './Library';
import { AppBar, Toolbar, Typography, Button, IconButton, Box, ThemeProvider, createTheme, CssBaseline, Menu,
  MenuItem,Fab, Drawer} from '@mui/material';
  import { AccountCircle, Add, Close, CheckCircleOutline, ErrorOutline, HourglassEmpty,List  } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { selectRemainCredit } from '../redux/creditSlice';
import LandingPage from './LandingPage';
import { Client } from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import {logout as LoginApi} from '../api'
import {logout}  from '../redux/authSlice';
import { refreshImages } from '../redux/imageSlice';
import TaskBox from './taskbox/TaskBox';
import WebSocketManager from './websocket/WebSocketManager';
import companyLogo from '../image/companyLogo.png';
import { useTranslation } from 'react-i18next';
import '../i18n';
// 创建深色主题
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#3f51b5',
    },
    background: {
      default: '#121212',
      paper: '#1e1e1e',
    },
  },
});

function HomePage() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const remainCredit = useSelector(selectRemainCredit);
  const [activeComponent, setActiveComponent] = useState('1minsDesign');
  const [anchorEl, setAnchorEl] = useState(null);
  const [isTaskBoxOpen, setIsTaskBoxOpen] = useState(false);
  const [taskList, setTaskList] = useState([]);

  const updateTaskList = useCallback((newTaskData) => {
    setTaskList(prevTaskList => {
      const taskIndex = prevTaskList.findIndex(task => task.taskId === newTaskData.taskId);
      if (taskIndex !== -1) {
        // 任务已存在，更新它
        const updatedList = [...prevTaskList];
        updatedList[taskIndex] = { ...updatedList[taskIndex], ...newTaskData };
        return updatedList;
      } else {
        // 任务不存在，添加新任务
        return [...prevTaskList, newTaskData];
      }
    });
  }, []);

  const handleWebSocketMessage = useCallback((message) => {
    switch (message.signalType) {
      case 'TASK_PROGRESS_UPDATE':
      case 'TASK_PROGRESS_COMPLETED':
        updateTaskList(message);
        setIsTaskBoxOpen(true);
        if (message.signalType === 'TASK_PROGRESS_COMPLETED') {
          dispatch(refreshImages());
        }
        break;
      default:
        console.log('Unhandled message type:', message.signalType);
    }
  }, [dispatch, updateTaskList]);  // 添加所需的依賴

  useEffect(() => {
    const accountId = localStorage.getItem("accountId");
    
    // 连接 WebSocket
    WebSocketManager.connect(accountId);

    // 添加消息监听器
    WebSocketManager.addMessageListener('HomePage', handleWebSocketMessage);

    // 组件卸载时清理
    return () => {
      WebSocketManager.removeMessageListener('HomePage');
      WebSocketManager.disconnect();
    };
  }, [handleWebSocketMessage]);  // 添加 handleWebSocketMessage 作为依赖

  // ... 其他函數定義


  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    localStorage.clear();
    dispatch(logout());
    await LoginApi();
    navigate('/');
  };

  const handleClearTask = (taskId) => {
    setTaskList(prevTaskList => prevTaskList.filter(task => task.taskId !== taskId));
    if (taskList.length === 1) {
      setIsTaskBoxOpen(false);
    }
  };

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case '1minsDesign':
        return <OneMinsDesign />;
      case 'Library':
        return <Library />;
      case 'Home':
      default:
        return <LandingPage />;
    }
  };

  const toggleTaskBox = () => {
    setIsTaskBoxOpen(!isTaskBoxOpen);
  };

  const getTaskIcon = () => {
    if (taskList.length === 0) {
      return <List />;
    }
    
    const hasInProgressTask = taskList.some(task => (task.taskStatus === 'PENDING' || task.taskStatus === 'INITIAL'));
    const hasErrorTask = taskList.some(task => task.taskStatus === 'ERROR');
    
    if (hasErrorTask) {
      return <ErrorOutline />;
    } else if (hasInProgressTask) {
      return <HourglassEmpty />;
    } else {
      return <CheckCircleOutline />;
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div className="home-page">
        <AppBar position="static" color="primary">
          <Toolbar>
             {/* Logo 在左邊 */}
            <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 0 }}>
            <img 
                      src={companyLogo}
                      alt="1Mins Logo" 
                      style={{ height: '40px', marginRight: '10px' }}
                    />
            </Box>

            {/* 佔滿中間空間的 Box */}
            <Box sx={{ flexGrow: 1 }} />
           
            <Button color="inherit" onClick={() => setActiveComponent('Home')}> {t('topBar.homeButton')}</Button>
            <Button color="inherit" onClick={() => setActiveComponent('1minsDesign')}>Design</Button>
            {/* <Button color="inherit" onClick={() => setActiveComponent('Library')}>Library</Button> */}
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem>
                {t('topBar.profileCredit')} {remainCredit !== null ? remainCredit : 'Loading...'}
              </MenuItem>
              <MenuItem onClick={handleLogout}>{t('logout')}</MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>

        <div className="content" style={{ display: 'flex', backgroundColor: '#121212' }}>
          <main style={{ flexGrow: 1, padding: 24 }}>
            {renderActiveComponent()}
          </main>
        </div>

        <Fab
        color="primary"
        aria-label="toggle task box"
        style={{
          position: 'fixed',
          bottom: 20,
          right: 20,
          zIndex: 1000
        }}
        onClick={toggleTaskBox}
      >
        {getTaskIcon()}
      </Fab>

        <Drawer
          anchor="right"
          open={isTaskBoxOpen}
          onClose={() => setIsTaskBoxOpen(false)}
          variant="persistent"
          PaperProps={{
            style: {
              width: '350px',
              bottom: '80px',
              top: 'auto',
              right: '20px',
              borderRadius: '8px',
              height: 'auto',
              maxHeight: '60vh',
              overflow: 'hidden'
            }
          }}
        >
          <TaskBox 
            taskList={taskList} 
            onClose={() => setIsTaskBoxOpen(false)} 
            onClearTask={handleClearTask}
          />
        </Drawer>
      </div>
    </ThemeProvider>
  );
}

export default HomePage;