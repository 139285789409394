// creditSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  remainCredit: null,
};

const creditSlice = createSlice({
  name: 'credit',
  initialState,
  reducers: {
    updateRemainCredit: (state, action) => {
      state.remainCredit = action.payload;
    },
  },
});

export const { updateRemainCredit } = creditSlice.actions;

export default creditSlice.reducer;

// Selector
export const selectRemainCredit = (state) => state.credit.remainCredit;