// TaskBox.js
import React from 'react';
import { 
  Box, Typography, IconButton, Card, CardContent, 
  Chip, Divider, LinearProgress, Tooltip, List, ListItem
} from '@mui/material';
import { Close, CheckCircleOutline, ErrorOutline, HourglassEmpty, Delete } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import '../../i18n';

function TaskBox({ taskList, onClose, onClearTask }) {
  const { t, i18n } = useTranslation();
  const getStatusIcon = (status) => {
    switch(status.toLowerCase()) {
      case 'completed':
        return <CheckCircleOutline color="success" />;
      case 'error':
        return <ErrorOutline color="error" />;
      default:
        return <HourglassEmpty color="warning" />;
    }
  };

  const getStatusColor = (status) => {
    switch(status.toLowerCase()) {
      case 'completed':
        return 'success';
      case 'error':
        return 'error';
      default:
        return 'warning';
    }
  };

  return (
    <Card elevation={0} sx={{ height: '100%' }}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6" fontWeight="bold">{t('taskBox')}</Typography>
          <IconButton size="small" onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <Divider sx={{ mb: 2 }} />
        {taskList && taskList.length > 0 ? (
          <List>
            {taskList.map((taskData) => ( 
              <ListItem key={taskData.taskId} disablePadding>
                <Box width="100%" mb={2}>
                  <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                    <Typography variant="subtitle1">{taskData.taskType}</Typography>
                    <Box display="flex" alignItems="center">
                      <Chip
                        icon={getStatusIcon(taskData.taskStatus)}
                        label={taskData.taskStatus}
                        color={getStatusColor(taskData.taskStatus)}
                        size="small"
                        sx={{ mr: 1 }}
                      />
                      {taskData.taskStatus.toLowerCase() === 'completed' && (
                        <Tooltip title="Clear Completed Task">
                          <IconButton
                            size="small"
                            color="primary"
                            onClick={() => onClearTask(taskData.taskId)}
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                  </Box>
                  <Typography variant="body2" color="text.secondary" mb={1}>{t('progress')}:</Typography>
                  <Box sx={{ width: '100%', mb: 1 }}>
                    <LinearProgress 
                      variant="determinate" 
                      value={taskData.progress} 
                      sx={{ height: 10, borderRadius: 5 }}
                    />
                  </Box>
                  <Typography variant="body2" color="text.secondary" textAlign="center">
                    {`${Math.round(taskData.progress)}%`}
                  </Typography>
                </Box>
              </ListItem>
            ))}
          </List>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" height="100px">
            <Typography color="text.secondary">{t('noTaskAvailable')}</Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
}

export default TaskBox;