import React, { useState } from 'react';
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, Card, CardBody, CardTitle, CardImg } from 'reactstrap';
import { processImage } from '../api';

function TenSDesign() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [resultImages, setResultImages] = useState([]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
      setSelectedFile(file);
      setPreviewUrl(URL.createObjectURL(file));
    } else {
      alert("Please select a PNG or JPG file.");
      event.target.value = null;
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = error => reject(error);
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!selectedFile) {
      alert("Please select an image first.");
      return;
    }

    try {
      const base64String = await convertToBase64(selectedFile);
      console.log(base64String);
      // const response = await processImage([base64String]);

      // if (response.data.status === "SUCCESS") {
      //   setResultImages(response.data.resultImages);
      // } else {
      //   alert("Image processing failed. Please try again.");
      // }
    } catch (error) {
      console.error("Error processing image:", error);
      alert("An error occurred while processing the image.");
    }
  };

  return (
    <Container>
      <h2 className="mt-4 mb-4">Library</h2>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label for="imageUpload">Upload Image (PNG or JPG only)</Label>
          <Input 
            type="file" 
            name="imageUpload" 
            id="imageUpload" 
            accept=".png,.jpg,.jpeg" 
            onChange={handleFileChange}
          />
        </FormGroup>
        {previewUrl && (
          <div className="mt-3 mb-3">
            <h4>Preview:</h4>
            <img src={previewUrl} alt="Preview" style={{maxWidth: '100%', maxHeight: '300px'}} />
          </div>
        )}
        <Button color="primary" type="submit">Submit</Button>
      </Form>

      {resultImages.length > 0 && (
        <div className="mt-5">
          <h3>Result Images:</h3>
          <Row>
            {resultImages.map((image, index) => (
              <Col md={4} key={image.imageUUID}>
                <Card className="mb-4">
                  <CardImg top width="100%" src={`data:image/png;base64,${image.imageData}`} alt={image.imageName} />
                  <CardBody>
                    <CardTitle tag="h5">{image.imageName}</CardTitle>
                    <p>UUID: {image.imageUUID}</p>
                    <p>Batch ID: {image.batchId}</p>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      )}
    </Container>
  );
}

export default TenSDesign;