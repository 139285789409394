import React, { useState, useEffect } from 'react';
import { Button, Typography, Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTranslation } from 'react-i18next';
import '../../i18n';

const StyledButton = styled(Button)(({ theme }) => ({
  minWidth: '60px',
  margin: '4px',
  color: theme.palette.text.primary,
  backgroundColor: '#333',
  '&:hover': {
    backgroundColor: '#444',
  },
  '&.selected': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const PreviewBox = styled(Box)(({ theme }) => ({
  width: '400px',
  height: '500px',
  backgroundColor: '#1e1e1e',
  border: '1px solid #444',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 auto',
}));

const InnerPreviewBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#333',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'all 0.3s ease',
}));

const AspectRatioSelector = ({ currentRatio, onRatioSelect, onClose }) => {
  const { t, i18n } = useTranslation();
  const [selectedRatio, setSelectedRatio] = useState(currentRatio);
  const [previewRatio, setPreviewRatio] = useState(currentRatio);
  const [previewWidth, setPreviewWidth] = useState(312);
  const [previewHeight, setPreviewHeight] = useState(312);

  const ratioGroups = [
    { label: 'Portrait', ratios: ['10:16','9:16', '2:3', '3:4', '1;3'] },
    { label: 'Landscape', ratios: ['16:10', '16:9', '3:2', '4:3', '3:1'] },
    { label: 'Square', ratios: ['1:1'] },
  ];

  const updatePreview = (ratio) => {
    const [width, height] = ratio.split(':').map(Number);
    const maxWidth = 380;
    const maxHeight = 480;
    let newWidth, newHeight;

    if (width / height > maxWidth / maxHeight) {
      newWidth = maxWidth;
      newHeight = Math.round((maxWidth / width) * height);
    } else {
      newHeight = maxHeight;
      newWidth = Math.round((maxHeight / height) * width);
    }

    setPreviewWidth(newWidth);
    setPreviewHeight(newHeight);
    setPreviewRatio(ratio);
  };

  useEffect(() => {
    updatePreview(selectedRatio);
  }, [selectedRatio]);

  const handleRatioSelect = (ratio) => {
    setSelectedRatio(ratio);
    if (onRatioSelect) {
      onRatioSelect(ratio);
    }
  };

  const handleRatioHover = (ratio) => {
    updatePreview(ratio);
  };

  const handleRatioLeave = () => {
    updatePreview(selectedRatio);
  };

  return (
    <Box sx={{ bgcolor: '#121212', color: 'white', p: 3, borderRadius: 2, display: 'flex' }}>
      <Box sx={{ flex: 1, mr: 3 }}>
        <Typography variant="h6" gutterBottom>{t('selectAspectRatio')}</Typography>
        <PreviewBox>
          <InnerPreviewBox style={{ width: previewWidth, height: previewHeight }}>
            <Typography variant="body2">{previewRatio}</Typography>
          </InnerPreviewBox>
        </PreviewBox>
        {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
          <Typography variant="body2">Width {previewWidth}px</Typography>
          <Typography variant="body2">Height {previewHeight}px</Typography>
        </Box> */}
      </Box>
      <Box sx={{ flex: 1 }}>
        {ratioGroups.map((group, index) => (
          <Box key={index} sx={{ mb: 2 }}>
            <Typography variant="subtitle1" sx={{ color: '#999' }}>{group.label}</Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {group.ratios.map((ratio) => (
                <StyledButton
                  key={ratio}
                  onClick={() => handleRatioSelect(ratio)}
                  onMouseEnter={() => handleRatioHover(ratio)}
                  onMouseLeave={handleRatioLeave}
                  className={selectedRatio === ratio ? 'selected' : ''}
                  variant="outlined"
                >
                  {ratio}
                </StyledButton>
              ))}
            </Box>
          </Box>
        ))}
        <Button
          variant="contained"
          color="primary"
          startIcon={<CancelIcon />}
          onClick={onClose}
          sx={{ mt: 2, width: '100%' }}
        >
          {t('cancel')}
        </Button>
      </Box>
    </Box>
  );
};

export default AspectRatioSelector;