import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Client } from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import { 
  Container, 
  Box, 
  TextField, 
  Button, 
  Typography, 
  Paper,
  ThemeProvider,
  createTheme,
  CssBaseline
} from '@mui/material';
import { login } from '../redux/authSlice';
import { login as loginApi } from '../api';
import Constant from '../Constant';
import { updateRemainCredit } from '../redux/creditSlice';
import companyLogoImg from '../image/companyLogo.png';
import { useTranslation } from 'react-i18next';
import '../i18n';

// 创建深色主题
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9',
    },
    secondary: {
      main: '#f48fb1',
    },
    background: {
      default: '#121212',
      paper: '#1e1e1e',
    },
  },
});

function LoginPage() {
  const { t, i18n } = useTranslation();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await loginApi(username, password);
      if(response.data.status === Constant.API_RESP_STATUS.SUCCESS) {
        dispatch(login({ user:username ,jwt: response.data.jwt }));
        localStorage.setItem('jwt', response.data.jwt);
        localStorage.setItem('accountId', response.data.accountId);
        if (response.data.remainCredit !== undefined) {
          dispatch(updateRemainCredit(response.data.remainCredit));
        }
        

        navigate('/home');
      } else {
        alert(t('errorMsg.loginFailedErrorMsg'));
      }
    } catch (error) {
      alert(t('errorMsg.loginFailedErrorMsg'), error);
    }
  };


  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            component="img"
            sx={{
              height: 256,
              width: 256,
              mb: 2
            }}
            alt="Company logo"
            src={companyLogoImg}
          />
          <Paper elevation={3} sx={{ mt: 3, p: 4, width: '100%', bgcolor: 'background.paper' }}>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label={t('loginName')}
                name="username"
                autoComplete="username"
                autoFocus
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                variant="outlined"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label={t('password')}
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                variant="outlined"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                {t('loginPageSignInButton')}
              </Button>
            </Box>
          </Paper>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default LoginPage;