import React, { useState, useCallback, forwardRef, useImperativeHandle,useEffect  } from 'react';
import { Grid, useMediaQuery  } from '@mui/material';
import ResultImageCell from './ResultImageCell';
import { getGeneratedImages } from '../../api';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { updateRemainCredit } from '../../redux/creditSlice';
import { refreshImagesDone } from '../../redux/imageSlice';
const ResultImageList = forwardRef(({ onMoreClick, onView, onUseAsTarget }, ref) => {
  const [images, setImages] = useState([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(12);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));
  const isSmall = useMediaQuery(theme.breakpoints.only('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.between('md', 'lg'));

  const shouldRefresh = useSelector((state) => state.image.shouldRefresh);
  const dispatch = useDispatch();

  const fetchImages = useCallback(async () => {
    if (loading || !hasMore) return;
    setLoading(true);
    try {
      const response = await getGeneratedImages(page, size);
      if (response.data.remainCredit) {
        dispatch(updateRemainCredit(response.data.remainCredit));
      }
      const newImages = response.data.resultImages.content;
      setImages(prevImages => [...prevImages, ...newImages]);
      setHasMore(newImages.length === size);
      setPage(prevPage => prevPage + 1);
    } catch (error) {
      console.error('Failed to fetch images:', error);
    } finally {
      setLoading(false);
    }
  }, [page, size, loading, hasMore]);

  // 根據屏幕大小決定每個 Grid item 的寬度
  const getGridSize = () => {
    if (isExtraSmall) return 12; // 1 張圖片 (整行寬度)
    if (isSmall) return 6;       // 2 張圖片
    if (isMedium) return 3;      // 4 張圖片
    return 3;                    // 默認 4 張圖片 (大屏幕)
  };

  const refreshImages = useCallback(async () => {
    try {
      const response = await getGeneratedImages(0, 4);
      const newImages = response.data.resultImages.content;
  
      setImages(prevImages => {
        const updatedImages = [...newImages];
        
        prevImages.forEach(prevImage => {
          if (!updatedImages.some(newImage => newImage.imageId === prevImage.imageId)) {
            updatedImages.push(prevImage);
          }
        });
  
        return updatedImages;
      });
  
      setHasMore(true); 
      dispatch(refreshImagesDone());
    } catch (error) {
      console.error('Failed to refresh images:', error);
    }
  }, []);

  useEffect(() => {
    if (shouldRefresh) {
      console.log('Refreshing images...');
      refreshImages();
    }
  }, [shouldRefresh, refreshImages]);

  useImperativeHandle(ref, () => ({
    refreshImages,
    fetchImages,
    hasMore,
    loading
  }));

  return (
    <Grid container spacing={2}>
      {images.map((image, index) => (
         <Grid item xs={getGridSize()} key={index}>
          <ResultImageCell
            key={image.id || index}
            image={image}
            onMoreClick={onMoreClick}
            onView={onView}
            onUseAsTarget={onUseAsTarget}
          />
         </Grid>
      ))}
    </Grid>
  );
});

export default ResultImageList;