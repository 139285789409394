import React, { useState, useRef, useEffect } from 'react';
import { CircularProgress, Container, Box, Typography, IconButton, Button, Grid2 as Grid, Select, FormControl, InputLabel, Modal, Fade, Backdrop, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import { generateImage, remixImage } from '../../api';
import AspectRatioSelector from './AspectRatioSelector';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ResultImageList from './ResultImageList';
import ImageIcon from '@mui/icons-material/Image';
import { updateRemainCredit } from '../../redux/creditSlice';
import { useDispatch, useSelector } from 'react-redux';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { useTranslation } from 'react-i18next';
import '../../i18n';


const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: 800,
  bgcolor: '#121212',
  border: '2px solid #2c2c2c',
  boxShadow: 24,
  p: 4,
  color: '#ffffff',
};


const DarkButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#333333',
  color: '#ffffff',
  '&:hover': {
    backgroundColor: '#444444',
  },
  '&.Mui-disabled': {
    backgroundColor: '#222222',
    color: '#666666',
  },
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: 800,
  bgcolor: '#121212',
  border: '2px solid #2c2c2c',
  boxShadow: 24,
  p: 4,
  color: '#ffffff',
};

const ImagePlaceholder = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 300,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  border: `2px dashed ${theme.palette.grey[500]}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.grey[900],
  color: theme.palette.grey[500],
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.grey[800],
  },
}));


function OneMinsDesign() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [targetImageData, setTargetImageData] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRatio, setSelectedRatio] = useState('1:1');
  const [anchorEl, setAnchorEl] = useState(null);
  const [processingOption, setProcessingOption] = useState('REMOVE_PRODUCT_AND_WORD');

  const handleOptionChange = (event) => {
    setProcessingOption(event.target.value);
  };

  const listRef = useRef(null);
  const containerRef = useRef(null);

  const handleRatioSelect = (ratio) => {
    console.log('Selected ratio:', ratio);
    setSelectedRatio(ratio);
    closeModal();
  };

  const handleMoreClick = (event, image) => {
    setAnchorEl(event.currentTarget);
    setSelectedImage(image);
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);


  const handleView = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };


  const handleClose = () => setOpen(false);

  const handleFileChange = async (event) => {
    if (isProcessing) return;
    const file = event.target.files[0];
    if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
      try {
        const base64String = await convertToBase64(file);
        setTargetImageData({ imageData: base64String });
        setPreviewUrl(URL.createObjectURL(file));
      } catch (error) {
        console.error("Error converting file to base64:", error);
      }
    } else {
      alert("Please select a PNG or JPG file.");
      event.target.value = null;
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = error => reject(error);
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!targetImageData || isProcessing) {
      return;
    }

    setIsProcessing(true);
    try {
      const response = await generateImage([targetImageData.imageData], selectedRatio, processingOption);

      if (response.data.status === "SUCCESS") {
        if (response.data.remainCredit) {
          dispatch(updateRemainCredit(response.data.remainCredit));
        }
      } else {
        alert("Image processing failed. Please try again.");
      }
    } catch (error) {
      console.error("Error processing image:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleRemix = async () => {
    if (!targetImageData || isProcessing) {
      return;
    }

    setIsProcessing(true);
    try {
      const response = await remixImage(targetImageData.imageId, selectedRatio);

      if (response.data.status === "SUCCESS") {
        if (response.data.remainCredit) {
          dispatch(updateRemainCredit(response.data.remainCredit));
        }
      } else {
        alert("Image remixing failed. Please try again.");
      }
    } catch (error) {
      console.error("Error remixing image:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleUseAsTarget = (image) => {
    console.log(image);
    if (window.confirm(t(''))) {
      if (isProcessing) return;
      setTargetImageData(image);
      setPreviewUrl(`data:image/png;base64,${image.imageData}`);
      handleClose();
    }
  };




  return (
    <Container ref={containerRef} maxWidth="lg" sx={{ bgcolor: '#121212', color: 'white', minHeight: '100vh', overflowY: 'auto' }}>
      <Box sx={{ my: 4, textAlign: 'center' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          1mins Design
        </Typography>
        <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="center" alignItems="flex-start">
          <Box sx={{ width: '100%', maxWidth: 400, mb: 2, mr: { sm: 2 } }}>
            {previewUrl ? (
              <ImagePlaceholder component="label">
                <Box
                  component="img"
                  sx={{
                    width: '100%',
                    height: 300,
                    objectFit: 'contain',
                    borderRadius: 1,
                  }}
                  src={previewUrl}
                  alt="Target Image"
                />
              </ImagePlaceholder>
            ) : (
              <ImagePlaceholder component="label">
                <input
                  type="file"
                  accept=".png,.jpg,.jpeg"
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />
                <ImageIcon sx={{ fontSize: 60, mb: 2 }} />
                <Typography>{t('clickOrDragToUpload')}</Typography>
              </ImagePlaceholder>
            )}
          </Box>
          <Box>
            <DarkButton
              component="label"
              variant="contained"
              startIcon={<AspectRatioIcon />}
              sx={{ mb: 2, width: '100%' }}
              disabled={isProcessing}
              onClick={openModal}
            >
              {t('ratio')}: {selectedRatio || 'None'}
            </DarkButton>
            <Modal
              open={isModalOpen}
              onClose={closeModal}
              closeAfterTransition
              slots={{ backdrop: Backdrop }}
              slotProps={{
                backdrop: {
                  timeout: 500,
                },
              }}
            >
              <Fade in={isModalOpen}>
                <Box sx={modalStyle}>
                  <AspectRatioSelector
                    currentRatio={selectedRatio}
                    onRatioSelect={handleRatioSelect}
                    onClose={closeModal}
                  />
                </Box>
              </Fade>
            </Modal>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <DarkButton
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
                sx={{ mb: 2, width: '100%' }}
                disabled={isProcessing}
              >
                {t('uploadImage')}
                <VisuallyHiddenInput
                  type="file"
                  accept=".png,.jpg,.jpeg"
                  onChange={handleFileChange}
                />
              </DarkButton>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="processing-option-label">{t('processOption')}</InputLabel>
                <Select
                  labelId="processing-option-label"
                  id="processing-option-select"
                  value={processingOption}
                  label="Processing Option"
                  onChange={handleOptionChange}
                  disabled={isProcessing}
                >
                  <MenuItem value="REMOVE_PRODUCT_AND_WORD">{t('processOptions.removeProductAndWord')}</MenuItem>
                  <MenuItem value="REMOVE_EXCEPT_BACKGROUND">{t('processOptions.removeExceptBackground')}</MenuItem>
                </Select>
              </FormControl>
              <Box sx={{ position: 'relative', display: 'block' }}>
                <DarkButton
                  type="submit"
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                  disabled={isProcessing || !targetImageData}
                  sx={{ width: '100%' }}
                >
                  {t('processImage')}
                </DarkButton>
                {isProcessing && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )}
              </Box>
              {/* <Box sx={{ position: 'relative', display: 'block', marginTop: '10px' }}>
                <DarkButton
                  onClick={handleRemix}
                  variant="contained"
                  startIcon={<AutoFixHighIcon />}
                  disabled={isProcessing || !targetImageData}
                  sx={{ width: '100%' }}
                >
              {t('remixImage')}
                </DarkButton>
                {isProcessing && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )}
              </Box> */}
            </Box>
          </Box>
        </Box>

        <ResultImageList
          ref={listRef}
          onMoreClick={handleMoreClick}
          onView={handleView}
          onUseAsTarget={handleUseAsTarget}
        />
        {listRef.current?.loading && <Box sx={{ textAlign: 'center', my: 2 }}>{t('loading')}</Box>}
        

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              {selectedImage && (
                <>
                  <Typography id="transition-modal-title" variant="h6" component="h2">
                    {selectedImage.imageName}
                  </Typography>
                  <Box
                    component="img"
                    sx={{
                      width: '100%',
                      maxHeight: '60vh',
                      objectFit: 'contain',
                      my: 2
                    }}
                    src={`data:image/png;base64,${selectedImage.imageData}`}
                    alt={selectedImage.imageName}
                  />
                  <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                  {/*  { <DarkButton variant="contained" onClick={() => handleUseAsTarget(selectedImage)}>
                      Remix
                    </DarkButton>} */}
                    <DarkButton variant="outlined" onClick={handleClose}>
                      {t('close')}
                    </DarkButton>
                  </Box>
                </>
              )}
            </Box>
          </Fade>
        </Modal>


      </Box>
    </Container>
  );
}

export default OneMinsDesign;