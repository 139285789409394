// redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import creditReducer from './creditSlice';
import imageReducer from './imageSlice';
// 導入其他 reducers...

const store = configureStore({
  reducer: {
    auth: authReducer,
    credit: creditReducer,
    image: imageReducer,
  },
});

export default store;