import React from 'react';
import { Grid, Card, CardMedia, IconButton, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'; // 魔術棒圖標
import { useTranslation } from 'react-i18next';
import '../../i18n';

const ImageCard = styled(Card)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  paddingTop: '100%', // 1:1 Aspect Ratio
  '&:hover .overlay': {
    opacity: 1,
  },
}));

const ImageOverlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  right: 0,
  padding: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  opacity: 0,
  transition: 'opacity 0.3s',
}));

const StyledCardMedia = styled(CardMedia)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'contain',
});

function ResultImageCell({ image, onMoreClick, onView, onUseAsTarget }) {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
    onMoreClick(event, image);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleView = () => {
    onView(image);
    handleClose();
  };

  const handleUseAsTarget = () => {
    onUseAsTarget(image);
    handleClose();
  };

  return (
    <Grid item>
      <ImageCard>
        <StyledCardMedia
          component="img"
          image={`data:image/png;base64,${image.imageData}`}
          alt={`Generated image ${image.imageName}`}
          onClick={handleView}
        />
        <ImageOverlay className="overlay">
          {/* <IconButton
            aria-label="magic wand"
            onClick={handleUseAsTarget}
            sx={{ color: 'white', marginRight: 1 }}
          >
            <AutoFixHighIcon />
          </IconButton> */}
          <IconButton
            aria-label="more"
            onClick={handleMoreClick}
            sx={{ color: 'white' }}
          >
            <MoreVertIcon />
          </IconButton>
        </ImageOverlay>
      </ImageCard>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleView}>{t('view')}</MenuItem>
        {/* <MenuItem onClick={handleUseAsTarget}>Use as target</MenuItem> */}
      </Menu>
    </Grid>
  );
}

export default ResultImageCell;