import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { Provider, useSelector, useDispatch } from 'react-redux';
import LoginPage from './components/LoginPage';
import HomePage from './components/HomePage';
import store from './redux/store';
import { setAuthState } from './redux/authSlice';
import { setNavigate, getSystemProfile } from './api';
import { updateRemainCredit } from './redux/creditSlice';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <AppContent />
      </Router>
    </Provider>
  );
}

function AppContent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    checkAuthStatus();
  }, []);

  useEffect(() => {
    setNavigate(navigate);
  }, [navigate]);

  const checkAuthStatus = async () => {
    const jwt = localStorage.getItem('jwt');
    console.log(jwt);
    if (jwt) {
      try {
        const response = await getSystemProfile();
  
        if (response.data.status === "SUCCESS") {
          if (response.data.remainCredit) {
            dispatch(updateRemainCredit(response.data.remainCredit));
          }
          dispatch(setAuthState({
            isAuthenticated: true,
            jwt: jwt
          }));
          navigate("/home")
        } else {
          alert("Please re login");
        }
      } catch (error) {
        console.error("Error processing image:", error);
      } 
    }
  };

  return (
    <div className="app-container">
      <div className="d-flex">
        <div className="content">
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/home" element={<HomePage />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default App;