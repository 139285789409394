import axios from 'axios';
import store from '../redux/store';
import { updateRemainCredit } from '../redux/creditSlice';

// 创建一个 axios 实例，设置基本 URL 和其他配置
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:4666/',
  headers: {
    'Content-Type': 'application/json',
  },
});

// 请求拦截器：在请求发送之前添加认证 token
api.interceptors.request.use((config) => {
  const jwt = localStorage.getItem('jwt');
  if (jwt) {
    config.headers.Authorization = `Bearer ${jwt}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

let navigate;
export const setNavigate = (nav) => {
  navigate = nav;
};

// 响应拦截器：集中处理错误
api.interceptors.response.use(
  (response) => {
    // Check if remainCredit is in the response data
    if (response.data && response.data.remainCredit !== undefined) {
      // Dispatch an action to update remainCredit in Redux store
      store.dispatch(updateRemainCredit(response.data.remainCredit));
    }
    return response;
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // 处理未授权错误
          alert('Unauthorized, please re-login');
          if (navigate) navigate('/');
          // 可以在这里触发登出操作或重定向到登录页面
          // 例如：store.dispatch(logout());
          
          break;
        case 403:
          // 处理禁止访问错误
          console.log('Forbidden access');
          break;
        case 404:
          // 处理未找到错误
          console.log('Resource not found');
          break;
        case 500:
          // 处理服务器错误
          alert('Server error');
          if (navigate) navigate('/');
          break;
        default:
          // 处理其他错误
          console.log('An error occurred:', error.response.status);
      }
    } else if (error.request) {
      // 请求已经发出，但没有收到响应
      console.log('No response received:', error.request);
    } else {
      // 设置请求时发生的错误
      console.log('Error setting up request:', error.message);
    }
    // 可以选择在这里显示一个全局错误通知
    // 例如：showErrorNotification(error.message);
    
    return Promise.reject(error);
  }
);

// 登录 API
export const login = (loginName, password) => {
  return api.post('/pub/authentication/login', { loginName, password });
};

export const getGeneratedImages = (page, size) => {
  return api.post('/sec/design/getgeneratedimages', { page, size });
};

// 处理图片的 API
export const generateImage = (imageDataList, aspectRatio, serviceOption) => {
  return api.post('/sec/design/generate', { imageDataList, aspectRatio, serviceOption });
};

export const remixImage = (imageId, aspectRatio) => {
  return api.post('/sec/design/remix', { imageId, aspectRatio });
};

// 登出 API
export const logout = () => {
  return api.post('/pub/authentication/logout',{});
};

export const getSystemProfile = () => {
  return api.post('/sec/system/getsystemprofile', {});
};

// 你可以继续添加其他 API 调用...

export default api;