import React from 'react';
import {
  ThemeProvider,
  createTheme,
  CssBaseline,
  Container,
  Typography,
  Button,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import { ExpandMore, Check, ArrowForward } from '@mui/icons-material';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const LandingPage = () => {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Container maxWidth="md">
        <Box sx={{ my: 4 }}>
          {/* 1. 標題 */}
          <Typography variant="h2" component="h1" gutterBottom align="center">
            AI驅動的營銷圖片生成器
          </Typography>

          {/* 2. 副標題 */}
          <Typography variant="h5" align="center" color="text.secondary" paragraph>
            在幾秒鐘內創建引人注目的營銷圖片，提升您的品牌影響力
          </Typography>

          {/* 3. CTA按鈕 */}
          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
            <Button variant="contained" size="large" endIcon={<ArrowForward />}>
              立即開始免費試用
            </Button>
          </Box>

          {/* 4. 主要特點 */}
          <Box sx={{ mt: 6 }}>
            <Typography variant="h4" gutterBottom>
              主要特點
            </Typography>
            <List>
              {['AI智能生成', '多樣化模板', '快速編輯', '高品質輸出'].map((feature, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <Check color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={feature} />
                </ListItem>
              ))}
            </List>
          </Box>

          {/* 6. 使用步驟 */}
          <Box sx={{ mt: 6 }}>
            <Typography variant="h4" gutterBottom>
              如何使用
            </Typography>
            <Stepper orientation="vertical">
              {['選擇模板', '輸入內容', '自定義設計', '下載圖片'].map((step, index) => (
                <Step key={index} active={true}>
                  <StepLabel>{step}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>

          {/* 8. FAQ */}
          <Box sx={{ mt: 6 }}>
            <Typography variant="h4" gutterBottom>
              常見問題
            </Typography>
            {[
              { question: '如何開始使用?', answer: '註冊帳號後，您就可以立即開始使用我們的圖片生成器。' },
              { question: '支持哪些圖片格式?', answer: '我們支持JPG、PNG和SVG格式的圖片輸出。' },
              { question: '是否有使用限制?', answer: '免費帳戶每月可生成50張圖片，付費帳戶則無限制。' },
            ].map((faq, index) => (
              <Accordion key={index}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  <Typography>{faq.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{faq.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default LandingPage;