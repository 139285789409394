// src/redux/imageSlice.js
import { createSlice } from '@reduxjs/toolkit';

const imageSlice = createSlice({
  name: 'images',
  initialState: {
    shouldRefresh: false,
  },
  reducers: {
    refreshImages: (state) => {
      state.shouldRefresh = true;
    },
    refreshImagesDone: (state) => {
      state.shouldRefresh = false;
    },
  },
});

export const { refreshImages, refreshImagesDone } = imageSlice.actions;
export default imageSlice.reducer;